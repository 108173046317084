import { ReactElement } from 'react';
import { useParams } from "react-router";
import FirebaseDataModel from '../../../../data/FirebaseDataModel';
import CanvasScreen, { Props as CanvasScreenProps, State as CanvasScreenState } from '../CanvasScreen';
import RestClient from "../../../../rest/RestClient";
import FirebaseComponent from '../../../../data/components/FirebaseComponent';
import { ComponentErrors } from '../../../../validation/ModelValitador';
import ModalBoxType from '../../../modalbox/ModalBoxType';
import CausalLoopGraph from './graph/CausalLoopGraph';
import UserControls from '../../../maxgraph/UserControls';
import CausalLoopPresentationGetter from './graph/presentation/CausalLoopPresentationGetter';
import CanvasSidebar from '../toolbar/CanvasSidebar';
import { UiMode } from '../UiMode';
import CausalLoopSidebar from './toolbar/CausalLoopSidebar';
import CausalLoopToolbar from './toolbar/CausalLoopToolbar';
import FirebaseSessionDataGetter from '../../../../data/FirebaseSessionDataGetter';
import { Cell } from '@maxgraph/core';
import CausalLoopModeManager from './graph/CausalLoopModeManager';
import UserActionLogger from '../../../../logging/UserActionLogger';
import CausalLoopActions from './graph/behaviour/CausalLoopActions';
import ModeSelectPanel from '../toolbar/ModeSelectPanel';
import ModeManager from '../ModeManager';

interface Props extends CanvasScreenProps {
    firebaseDataModel: FirebaseDataModel;
    restClient: RestClient;
    modelUuid?: string;
}

interface State extends CanvasScreenState {
    modelName: string | null;
    components: FirebaseComponent[];
    clipboard: FirebaseComponent[];
    errors: ComponentErrors;
    displayedModalBox: ModalBoxType | null;
    sidebarWidth: number;
    sidebarVisible: boolean;
    selectedComponent: FirebaseComponent | null;
}

class CausalLoopScreen extends CanvasScreen<Props, State, CausalLoopGraph> {

    private static readonly presentation = new CausalLoopPresentationGetter();

    private readonly actionLogger = new UserActionLogger();

    protected subscribeToFirebase(): () => void {
        return new FirebaseSessionDataGetter(
            this.props.firebaseDataModel
        ).loadCausalLoopModel(
            this.props.modelUuid!,
            n => this.setState({ modelName: n }),
            c => this.onComponentsUpdated(c),
        );
    }

    protected onComponentsUpdated(components: FirebaseComponent[]): void {
        const tryUpdateGraph = () => {
            this.graph != null
                ? this.graph.refreshComponents(
                    components,
                    oldComponents,
                    errors
                )
                : setTimeout(tryUpdateGraph, 200);
        }
        const errors = {}; // TODO
        const oldComponents = this.state.components;
        this.setState({ components, errors });
        tryUpdateGraph();
    }

    protected makeInitialState(): State {
        return {
            modelName: null,
            components: [],
            clipboard: [],
            errors: {},
            displayedModalBox: null,
            sidebarWidth: CanvasSidebar.DEFAULT_WIDTH_PX,
            sidebarVisible: CanvasSidebar.DEFAULT_VISIBILITY,
            cursorPosition: CanvasScreen.INIT_CURSOR,
            keydownPosition: null,
            keydownCell: null,
            mode: UiMode.MOVE,
            hoverCell: null,
            selectedComponent: null,
        };
    }

    protected makeGraph(): CausalLoopGraph {
        return new CausalLoopGraph(
            this.graphRef.current!,
            this.props.firebaseDataModel,
            this.props.modelUuid!,
            CausalLoopScreen.presentation,
            () => this.state.components,
            () => [], // TODO add substitutions once they are added
            () => this.state.errors,
            () => { return {}; },
            () => this.state.mode,
            () => this.state.keydownCell !== null,
        );
    }

    protected makeModeManager(): ModeManager {
        if (!this.graph || !this.actions)
            throw new Error("Not initialized");
        return new CausalLoopModeManager(
            mode => this.setState({ mode }),
            this.graph,
            this.actions,
            () => this.state.components,
            m => this.setState({ displayedModalBox: m }),
            () => this.state.cursorPosition,
            () => this.state.keydownPosition,
            p => this.setState({ keydownPosition: p }),
            () => this.state.keydownCell,
            c => this.setState({ keydownCell: c }),
            () => this.state.hoverCell,
            c => this.setState({ hoverCell: c }),
        );
    }

    protected makeModeSelector(): ReactElement | null {
        if (!this.modeManager) return null;
        return (
            <ModeSelectPanel
                sx={{ left: 30, top: 30 }}
                mode={this.state.mode}
                changeMode={mode => this.setMode(mode)}
                modeManager={this.modeManager}
            />
        );
    }

    protected makeActions(): CausalLoopActions {
        if (!this.graph) throw new Error("Not initialized");
        if (!this.props.modelUuid) throw new Error("No UUID found");
        return new CausalLoopActions(
            this.props.firebaseDataModel,
            new CausalLoopPresentationGetter(),
            this.graph,
            this.props.modelUuid,
            () => this.state.components,
            this.actionLogger,
        );
    }

    protected makeUserControls(): UserControls {
        if (!this.graph || !this.actions) throw new Error("Not initialized");
        return new UserControls(
            this.graph,
            this.actions,
            this.makeBehaviourGetter(),
            c => this.setState({ clipboard: c }),
            () => this.pasteComponents(),
            () => this.state.components,
            () => this.state.mode,
            mode => this.setMode(mode),
            m => this.setState({ displayedModalBox: m }),
            () => this.state.cursorPosition,
            () => this.state.keydownPosition,
            p => this.setKeydownPosition(p),
            () => this.state.keydownCell,
            (c: Cell | null) => this.setKeydownCell(c),
            this.actionLogger,
        );
    }

    private makeBehaviourGetter(): CausalLoopModeManager {
        if (!this.graph || !this.actions) throw new Error("Not initialized");
        return new CausalLoopModeManager(
            mode => this.setMode(mode),
            this.graph,
            this.actions,
            () => this.state.components,
            m => this.setState({ displayedModalBox: m }),
            () => this.state.cursorPosition,
            () => this.state.keydownPosition,
            p => this.setState({ keydownPosition: p }),
            () => this.state.keydownCell,
            c => this.setState({ keydownCell: c }),
            () => this.state.hoverCell,
            c => this.setState({ hoverCell: c }),
        );
    }

    protected makeToolbar(): ReactElement {
        return (
            <CausalLoopToolbar
                setOpenModalBox={m => this.setState({ displayedModalBox: m })}
                modelName={this.state.modelName ?? ""}
                modelId={this.props.modelUuid!}
                restClient={this.props.restClient}
                firebaseDataModel={this.props.firebaseDataModel}
                toggleSidebarOpen={() => this.toggleSidebarOpen()}
                components={this.state.components}
                errors={this.state.errors}
                uiMode={this.state.mode}
            />
        );
    }

    protected makeSidebar(): ReactElement {
        return (
            <CausalLoopSidebar
                onResize={w => this.setState({ sidebarWidth: w })}
                getIsVisible={() => this.state.sidebarVisible}
                firebaseDataModel={this.props.firebaseDataModel}
                modelUuid={this.props.modelUuid!}
                components={this.state.components}
                selectedComponent={this.state.selectedComponent}
            />
        );
    }
}

export default function CausalLoopScreenWithParams(props: Props) {
    let { uuid } = useParams();
    return (<CausalLoopScreen {...props} modelUuid={uuid} />);
};
